import { Component } from '@angular/core';
import { NavigationService } from 'src/app/_services';

@Component({
    selector: 'unauthorized-content',
    templateUrl: './unauthorized-content.component.html',
    styleUrl: './unauthorized-content.component.scss'
})
export class UnauthorizedContentComponent {

    constructor(
        private navService: NavigationService,
    ) { }

    navigateToAppStore() {
        this.navService.navigateToAppStore()
    }

}
