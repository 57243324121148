import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Hive } from 'src/app/_models';
import { AuthenticationService, NavigationService } from 'src/app/_services';
import { PluralizePipe, FormatNumberPipe } from 'src/app/_pipes';
import { HiveAPIService } from 'src/app/_api-services';

@Component({
    selector: 'hive-card',
    templateUrl: './hive-card.component.html',
    styleUrl: './hive-card.component.scss'
})
export class HiveCardComponent implements OnDestroy, OnInit, OnChanges {
    @Input() hive!: Hive
    @Input() type: string = 'join'
    @Output() clicked: EventEmitter<void> = new EventEmitter<void>()

    private subscriptions: Subscription = new Subscription()

    userId: string | null = null
    joined: boolean = false
    joinBtnTxt: string = 'Join'
    loading: boolean = false
    secondaryTxt: string = ''
    currentDateTime: string = ''

    constructor(
        private hiveAPIService: HiveAPIService,
        private authService: AuthenticationService,
        private router: Router,
        private formatNumber: FormatNumberPipe,
        private pluralize: PluralizePipe,
        private cdr: ChangeDetectorRef,
        private navService: NavigationService,
    ) {
        let localStorage = this.authService.currentUserValue
        this.userId = localStorage.user.id
    }

    ngOnInit(): void {
        this.joined = this.hive.user_is_member
        if (this.type == "activity") {
            this.cdr.detectChanges()
            this.updateTime()
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['hive'].currentValue) {
            let members = this.hive.members ? this.hive.members.length : 0
            this.secondaryTxt = this.formatNumber.transform(members) + ' ' + this.pluralize.transform('Member', members)
        }
    }

    joinHive(event: MouseEvent): void {
        event.stopPropagation()
        event.preventDefault()

        this.navService.navigateToAppStore()
        return;

        if (!this.userId) {
            this.router.navigate(['/register'], { queryParams: { action: 'join', hive: this.hive.id } })
            return
        }

        if (this.joined) {
            this.router.navigate(['/', this.hive.handle, 'about'])
            return
        }

        this.loading = true
        this.joinBtnTxt = ''
        this.subscriptions.add(this.hiveAPIService.join(this.hive.handle).subscribe({
            next: hive => {
                this.joined = true
                this.joinBtnTxt = 'View'
            },
            error: err => {
                console.log(err)
                this.joinBtnTxt = 'Join'
            },
            complete: () => {
                this.loading = false
            }
        }))
    }

    viewClicked(url: string): void {
        this.router.navigateByUrl(url)
        this.clicked.emit()
    }

    updateTime(): void {
        this.currentDateTime = new Date().toISOString()
        this.cdr.markForCheck()
        setTimeout(() => this.updateTime(), 60000)
    }
}
