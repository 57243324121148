import { Injectable } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private history: string[] = []
    private currentPage: string = ""
    private prevPage: string = ""
    private prevNavigatedPage: string = ""
    private excludedPrefixes: string[] = ['/post']

    constructor(
        private router: Router,
        private cookieService: CookieService
    ) {
        this.router.events.pipe(
            filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.handleNavigation(event.url)
        })
    }

    private handleNavigation(url: string): void {
        this.prevPage = this.currentPage
        this.currentPage = url
    }

    getHistory(): string[] {
        return this.history
    }

    clearHistory(): void {
        this.history = []
        this.cookieService.delete('history')
    }

    handleInitialNavigation(): void {
        this.currentPage = this.router.url
    }

    addHistory(curUrl: string): void {
        let prevUrl = this.currentPage

        if (this.currentPage == curUrl) {
            prevUrl = this.prevPage
        }

        if (prevUrl != this.prevNavigatedPage && prevUrl != "/" && this.shouldStoreInHistory(prevUrl)) {
            this.history.push(prevUrl)
            this.saveToCookies()
        }

        this.prevNavigatedPage = '/'
    }

    shouldStoreInHistory(url: string): boolean {
        return !this.excludedPrefixes.some(prefix => url.startsWith(prefix))
    }

    getPreviousPage(): string {
        this.loadFromCookies()
        this.prevNavigatedPage = this.currentPage

        let lastPage = '/'
        if (this.history.length > 0) {
            lastPage = this.history[this.history.length - 1]
            this.history.pop()
            this.saveToCookies()
        }

        return lastPage
    }

    goBack(url: string = ''): void {
        let goTo = url

        if (!goTo) {
            goTo = this.getPreviousPage()
        }

        this.router.navigateByUrl(goTo)
    }

    private saveToCookies(): void {
        if (this.cookieService.check("history")) {
            this.cookieService.delete('history')
        }
        this.cookieService.set('history', JSON.stringify(this.history), { path: '/' })
    }

    private loadFromCookies(): void {
        const history = this.cookieService.get('history')
        if (history) {
            this.history = JSON.parse(history)
        }
    }


    private appleStoreUrl = 'https://apps.apple.com/us/app/hubhive/6593692270';
    private googlePlayStoreUrl = 'https://play.google.com/store/apps/details?id=com.hubhive.mobile';

    navigateToAppStore(): void {
        const userAgent = navigator.userAgent.toLowerCase();
        const platform = navigator.platform.toLowerCase();

        if (/android/.test(userAgent)) {
            // Android Device
            window.location.href = this.googlePlayStoreUrl;
        } else if (/iphone|ipad|ipod/.test(userAgent) || /macintosh/.test(platform) && 'ontouchend' in document) {
            // iOS (including iPads running iOS)
            window.location.href = this.appleStoreUrl;
        } else if (/macintosh/.test(platform)) {
            console.log("User is on MacOS");
        } else if (/win/.test(platform)) {
            console.log("User is on Windows");
        } else {
            console.log("Unknown OS");
        }
    }


}