import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusinessCardComponent } from './components/business-card/business-card.component'
import { BusinessAdminBarComponent } from './components/business-admin-bar/business-admin-bar.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { BusinessReviewOverviewComponent } from './components/business-review-overview/business-review-overview.component';
import { FormlessComponent } from './components/formless/formless.component';
import { DateTime, FormatNumberPipe, PhonePipe, PluralizePipe, TimeDifferencePipe, TimeDurationPipe, WeekDayDate, ReversePipe } from 'src/app/_pipes';
import { ModalComponent } from './components/modal/modal.component';
import { PhoneMaskDirective } from 'src/app/_directives';
import { StateSelectorComponent } from './components/state-selector/state-selector.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HiveToolbarComponent } from './components/hive-toolbar/hive-toolbar.component';
import { SearchComponent } from './components/search/search.component';
import { HiveCardComponent } from './components/hive-card/hive-card.component';
import { HiveCreateComponent } from './components/hive-create/hive-create.component';
import { HiveAddMembersComponent } from './components/hive-add-members/hive-add-members.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { ProductGridItemComponent } from './components/product-grid-item/product-grid-item.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { NavHomeComponent } from './components/nav-home/nav-home.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { AddMemberSearchComponent } from './components/add-member-search/add-member-search.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { FileDropzoneComponent } from './components/file-dropzone/file-dropzone.component';
import { UserOwnedEntitiesDropdownComponent } from './components/user-owned-entities-dropdown/user-owned-entities-dropdown.component';
import { UnregisteredUserCardComponent } from './components/unregistered-user-card/unregistered-user-card.component';
import { SplitCurrencyPipe } from 'src/app/_pipes/split-currency.pipe';
import { BusinessDetailsComponent } from './components/business-details/business-details.component';
import { BusinessAddComponent } from './components/business-add/business-add.component';
import { RouterModule } from '@angular/router';
import { ProductCreateComponent } from './components/product-create/product-create.component';
import { ProductCreateBusinessComponent } from './components/product-create-business/product-create-business.component';
import { ProductCreateContainerComponent } from './components/product-create-container/product-create-container.component';
import { CreateHiveContainerComponent } from './components/hive-create-container/hive-create-container.component';
import { EventCreateContainerComponent } from './components/event-create-container/event-create-container.component';
import { EventCreateComponent } from './components/event-create/event-create.component';
import { EventAddMembersComponent } from './components/event-add-members/event-add-members.component';
import { EventAddHivesComponent } from './components/event-add-hives/event-add-hives.component';
import { PublicPlusButtonComponent } from './components/public-plus-button/public-plus-button.component';
import { PlusButtonDialogComponent } from './components/public-plus-button/plus-button-dialog/plus-button-dialog.component';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BusinessNodeCardComponent } from './components/business-node-card/business-node-card.component';
import { EventNodeCardComponent } from './components/event-node-card/event-node-card.component';
import { NodeTileComponent } from './components/node-tile/node-tile.component';
import { PartOfHivesComponent } from './components/part-of-hives/part-of-hives.component';
import { ModalBottomComponent } from './components/modal-bottom/modal-bottom.component';
import { OptionsDropdownComponent } from './components/options-dropdown/options-dropdown.component';
import { AlertPopupComponent } from './components/alert-popup/alert-popup.component';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { ProductCreateOptionsComponent } from './components/product-create-options/product-create-options.component';
import { ProductScannerComponent } from './components/product-scanner/product-scanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SearchProductsComponent } from './components/search-products/search-products.component';
import { ProductResultsComponent } from './components/product-results/product-results.component';
import { EntityProfileCardComponent } from './components/entity-profile-card/entity-profile-card.component';
import { TheEndComponent } from './components/the-end/the-end.component';
import { TutorialSliderComponent } from './components/slider/tutorial-slider.component';
import { ProductCategorySelectorComponent } from './components/product-category-selector/product-category-selector.component';
import { DirectivesModule } from '../directives/directives.module';
import { StopPropagationDirective } from '../directives/directives/stop-propagation.directive';
import { BusinessAddHivesComponent } from './components/business-add-hives/business-add-hives.component';
import { EntitySearchComponent } from './components/entity-search/entity-search.component';
import { ImageSelectorComponent } from './components/image-selector/image-selector.component';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';
import { MissingAvatarComponent } from './components/missing-avatar/missing-avatar.component';
import { CountrySelectorComponent } from './components/country-selector/country-selector.component';
import { UnauthorizedContentComponent } from './components/unauthorized-content/unauthorized-content.component';


@NgModule({
	declarations: [
		PhonePipe,
		EventCardComponent,
		BusinessReviewOverviewComponent,
		BusinessAdminBarComponent,
		FormlessComponent,
		ModalComponent,
		StateSelectorComponent,
		NotFoundComponent,
		HiveToolbarComponent,
		SearchComponent,
		HiveCardComponent,
		HiveCreateComponent,
		HiveAddMembersComponent,
		PhoneMaskDirective,
		LoadingSpinnerComponent,
		FormatNumberPipe,
		PluralizePipe,
		ReversePipe,
		TimeDifferencePipe,
		ProductGridItemComponent,
		NavBarComponent,
		NavHomeComponent,
		UserCardComponent,
		AddMemberSearchComponent,
		ToolBarComponent,
		TimeDurationPipe,
		DateTime,
		WeekDayDate,
		FileDropzoneComponent,
		UserOwnedEntitiesDropdownComponent,
		UnregisteredUserCardComponent,
		SplitCurrencyPipe,
		BusinessDetailsComponent,
		BusinessAddComponent,
		ProductCreateComponent,
		ProductCreateBusinessComponent,
		ProductCreateContainerComponent,
		EventCreateContainerComponent,
		EventCreateComponent,
		EventAddMembersComponent,
		EventAddHivesComponent,
		PublicPlusButtonComponent,
		PlusButtonDialogComponent,
		CreateHiveContainerComponent,
		BusinessCardComponent,
  		NotificationCardComponent,
		ImageSliderComponent,
        BusinessNodeCardComponent,
        NodeTileComponent,
        PartOfHivesComponent,
        ModalBottomComponent,
		OptionsDropdownComponent,
		AlertPopupComponent,
		TagInputComponent,
		ProductCreateOptionsComponent,
		ProductScannerComponent,
		ProductResultsComponent,
  		SearchProductsComponent,
		EventNodeCardComponent,
        EntityProfileCardComponent,
        TheEndComponent,
        TutorialSliderComponent,
        ProductCategorySelectorComponent,
		BusinessAddHivesComponent,
        EntitySearchComponent,
        ImageSelectorComponent,
        ImageEditorComponent,
        MissingAvatarComponent,
        CountrySelectorComponent,
        UnauthorizedContentComponent,
	],
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule,
		SlickCarouselModule,
		FormsModule,
		ZXingScannerModule,
        DirectivesModule
	],
	exports: [
		PhonePipe,
		EventCardComponent,
		BusinessReviewOverviewComponent,
		BusinessAdminBarComponent,
		FormlessComponent,
		ModalComponent,
		StateSelectorComponent,
		NotFoundComponent,
		HiveToolbarComponent,
		SearchComponent,
		HiveCardComponent,
		HiveCreateComponent,
		HiveAddMembersComponent,
		PhoneMaskDirective,
		LoadingSpinnerComponent,
		FormatNumberPipe,
		PluralizePipe,
		ReversePipe,
		TimeDifferencePipe,
		ProductGridItemComponent,
		NavBarComponent,
		NavHomeComponent,
		UserCardComponent,
		AddMemberSearchComponent,
		ToolBarComponent,
		TimeDurationPipe,
		DateTime,
		WeekDayDate,
		FileDropzoneComponent,
		UserOwnedEntitiesDropdownComponent,
		UnregisteredUserCardComponent,
		ReactiveFormsModule,
		SplitCurrencyPipe,
		BusinessDetailsComponent,
		BusinessAddComponent,
		ProductCreateComponent,
		ProductCreateBusinessComponent,
		ProductCreateContainerComponent,
		EventCreateContainerComponent,
		EventCreateComponent,
		EventAddMembersComponent,
		EventAddHivesComponent,
		PublicPlusButtonComponent,
		PlusButtonDialogComponent,
		CreateHiveContainerComponent,
		BusinessCardComponent,
		NotificationCardComponent,
		ImageSliderComponent,
		SlickCarouselModule,
        BusinessNodeCardComponent,
        NodeTileComponent,
        PartOfHivesComponent,
        ModalBottomComponent,
		OptionsDropdownComponent,
		AlertPopupComponent,
		TagInputComponent,
		ProductResultsComponent,
		EventNodeCardComponent,
        EntityProfileCardComponent,
        TheEndComponent,
        TutorialSliderComponent,
        StopPropagationDirective,
		BusinessAddHivesComponent,
        EntitySearchComponent,
        ImageSelectorComponent,
        MissingAvatarComponent,
        CountrySelectorComponent,
	],
    providers: [FormatNumberPipe, PluralizePipe]
})
export class SharedCommonModule { 
}
