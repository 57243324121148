import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OptionDropdown, Product, ProductOffer } from 'src/app/_models';
import { NavigationService } from 'src/app/_services';

@Component({
    selector: 'product-grid-item',
    templateUrl: './product-grid-item.component.html',
    styleUrl: './product-grid-item.component.scss',
    providers: [CurrencyPipe]
})
export class ProductGridItemComponent implements OnInit {
    @Input() product!: Product
    @Input() productOffer!: ProductOffer
    @Input() offering: boolean = false
    @Input() options: OptionDropdown[] = []

    @Output() offerProduct: EventEmitter<null> = new EventEmitter<null>()

    formattedPrice: string = ''

    constructor(private currencyPipe: CurrencyPipe,
        private navService: NavigationService,
    ) { }

    ngOnInit(): void {
        if (this.product && this.product.variants) {
            this.formatPrice(this.product.variants[0].offers[0].price)
        }

        if (this.productOffer) {
            this.formatPrice(this.productOffer.price)
        }
    }

    formatPrice(price: number) {
        let formatted = this.currencyPipe.transform(price, 'USD')

        if (formatted) {
            const parts = formatted.split('.')
            if (parts.length === 2) {
                const mainPart = parts[0]
                const fractionalPart = parts[1]
                this.formattedPrice = `${mainPart}<span class="fractional">${fractionalPart}</span>`
            } else {
                this.formattedPrice = formatted
            }
        }
    }

    returnProduct() {
        this.navService.navigateToAppStore()
        return;
        this.offerProduct.emit()
    }

    navigateToAppStore() {
        this.navService.navigateToAppStore()
    }
}
