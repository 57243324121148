import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserAPIService } from 'src/app/_api-services';
import { AuthenticationService, ModalService, NavigationService } from 'src/app/_services';

@Component({
    selector: 'product-create-container',
    templateUrl: './product-create-container.component.html',
    styleUrl: './product-create-container.component.scss'
})
export class ProductCreateContainerComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()

    showCreateProduct: boolean = false
    showOptions: boolean = false
    showCreateBusiness: boolean = false
    userIsLoggedIn: boolean

    constructor(
        private userAPIService: UserAPIService,
        private authService: AuthenticationService,
        private modalService: ModalService,
        private router: Router,
        private navService: NavigationService,
    ) {
        this.userIsLoggedIn = this.authService.isLoggedIn()
        this.navService.navigateToAppStore()
        return;
        if (!this.userIsLoggedIn) {
            this.modalService.close()
            this.router.navigate(['/register'], { queryParams: { redirect: this.router.url } })
        }

        this.doCreateAction()
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }

    async doCreateAction(): Promise<void> {
        try {
            const ownsBusiness = await this.userOwnsBusiness()
            if (ownsBusiness) {
                this.showCreateProductForm()
                return
            }

            this.showOptionModal()
        } catch {
            this.showOptionModal()
        }
    }

    userOwnsBusiness(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.subscriptions.add(
                this.userAPIService.getUserBusinesses().subscribe({
                    next: businesses => {
                        if (!businesses || !businesses.length) {
                            resolve(false)
                        }

                        resolve(true)
                    },
                    error: err => {
                        reject(err)
                    }
                })
            )
        })
    }

    showCreateProductForm(): void {
        this.showOptions = false
        this.showCreateBusiness = false
        this.showCreateProduct = true
    }

    showOptionModal(): void {
        this.showCreateProduct = false
        this.showCreateBusiness = false
        this.showOptions = true
    }

    showAddBusiness(): void {
        this.modalService.updateTitle('Create New Business')
        this.modalService.activateFooterBtn()
        this.modalService.updateFooterBtnTxt('Create Business')
        this.showOptions = false
        this.showCreateBusiness = true
    }
}
